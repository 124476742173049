<template>
  <div class="body">
    <div class="type-one">
      <img class="img1" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1/%E5%9B%BE1.jpg" alt="">
      <img class="img2 animate__animated animate__fadeInDown " src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1/%E5%AD%971.png" alt="">
      <el-button @click="startFootFrom" class="linshibtn" type="primary">立即抢占海量客户</el-button>
    </div>
    <!-- <zhe></zhe> -->
    <!-- 底部弹起表单 -->
    <div class="from-foot " v-show="isFootFrom">
      <div class="from-foot-from animate__animated animate__fadeInUp ">
        <h3 @click="closeFootFrom">×</h3>
        <el-form :model="fromData" class="part-foot-from" label-position="left" label-width="50px" ref="fromData"
          :rules=rules>
          <el-form-item label="" prop="user" class="from-child">
            <el-input v-model.trim="fromData.user" placeholder="姓名"></el-input>
          </el-form-item>
          <el-form-item label="" prop="phone" class="from-child">
            <el-input v-model.trim="fromData.phone" placeholder="手机"></el-input>
          </el-form-item>
          <el-form-item label="" prop="name" class="from-child">
            <el-input v-model.trim="fromData.name" placeholder="公司名称"></el-input>
          </el-form-item>
          <el-form-item label="" prop="business" class="from-child">
          <el-input v-model.trim="fromData.business" placeholder="行业分类（不清楚可不填）"></el-input>
        </el-form-item>
          <el-button class="linshibtn" @click="submitFromData('fromData')" type="primary">立即咨询</el-button>
        </el-form>
      </div>
    </div>
    <!-- 第二部分 广告类型-->
    <div class="type-two">
      <ul class="type-two-tab">
          <li @click="isTypeTwo=0" :class="isTypeTwo==0?'type-active':''">锁屏广告</li>
          <li @click="isTypeTwo=1" :class="isTypeTwo==1?'type-active':''">开屏广告</li>
          <li @click="isTypeTwo=2" :class="isTypeTwo==2?'type-active':''">信息流广告</li>
          <li @click="isTypeTwo=3" :class="isTypeTwo==3?'type-active':''">天幕广告</li>
          <li @click="isTypeTwo=4" :class="isTypeTwo==4?'type-active':''">OPPO品牌广告</li>
    </ul>
    <div class="type-two-tabchild"></div>
    <div class="type-two-cont">
      <div v-show="isTypeTwo==0" class="type-two-cont-p animate__animated animate__fadeInLeft">
        <p class="p1">锁屏广告</p>
        <p class="p2">标准类广告资源基于手机自带各场景功能，实现广告投放</p>
        <p class="p2">任何手机都离不开锁屏应用，锁屏广告利用锁屏界面将广告进行投放，充分利用锁屏广告样式，加强品牌曝光</p>
        <p class="p2">极佳的内容品质，无打扰的沉浸式阅读；开屏即得，更短传播路径，更高的传播效率</p>
      </div>
      <div v-show="isTypeTwo==1" class="type-two-cont-p animate__animated animate__fadeInLeft">
        <p class="p1">开屏广告</p>
        <p class="p2">整合优质品牌广告流量，以开屏广告样式强势品牌曝光</p>
        <p class="p2">搭建oppo系列开屏产品矩阵，整合oppo优质广告资源与流量，以APP开屏广告的样式，强势品牌曝光，基于大数据赋能和OPPO独有的流量优势的优质移动营销产品</p>
        <p class="p2">吸引用户眼球，增强品牌的曝光度与用户点击率 ，是进入App的首要入口</p>
      </div>
      <div v-show="isTypeTwo==2" class="type-two-cont-p animate__animated animate__fadeInLeft">
        <p class="p1">信息流广告</p>
        <p class="p2">将您的推广信息自然融入在各类资讯、信息中，易传播，易操作</p>
        <p class="p2">信息流推广是在OPPO浏览器、OPPO视频、主题商店等平台的资讯流中穿插展现的原生广告，广告即是内容。</p>
        <p class="p2">根据客户搜索的关键词，在用户浏览资讯时，定向自然的展现营销内容，基于OPPO平台海量用户行为数据的分析</p>
      </div>
      <div v-show="isTypeTwo==3" class="type-two-cont-p animate__animated animate__fadeInLeft">
        <p class="p1">天幕广告</p>
        <p class="p2">标准类广告资源，依托于OPPO手机内部APP进行推广，视频全屏开屏 +推荐频道信息流</p>
        <p class="p2">天幕广告是指结合客户检索页样式，配合交互动效展示如舞台幕布似的全屏广告。</p>
        <p class="p2">全屏展示+信息流精准投放，吸引用户注意，多维度触达用户，位于首页置顶，组图、视频随意选择，广告内容一目了然，要点清晰</p>
      </div>
      <div v-show="isTypeTwo==4" class="type-two-cont-p animate__animated animate__fadeInLeft">
        <p class="p1">OPPO品牌广告</p>
        <p class="p2">基于OPPO的品牌效应，快速建立您的企业品牌公信力，首位霸屏，强展现</p>
        <p class="p2">前端的黄金位置，助力企业品牌形象的建立，加深客户的第一印象，精准定向活跃用户，多场景推广，抢占用户市场</p>
        <p class="p2">用户通过搜索关键词，即可出现相应的品牌专区，紧贴用户搜索场景体验</p>
      </div>
      <div class="type-two-img ">
        <img class="animate__animated animate__fadeInRight " v-show="isTypeTwo==0" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1%E9%94%81%E5%B1%8F%E5%B9%BF%E5%91%8A.png" alt="">
        <img class="animate__animated animate__fadeInRight " v-show="isTypeTwo==1" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/2%E5%BC%80%E5%B1%8F%E5%B9%BF%E5%91%8A.png" alt="">
        <img class="animate__animated animate__fadeInRight " v-show="isTypeTwo==2" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/3%E4%BF%A1%E6%81%AF%E6%B5%81%E5%B9%BF%E5%91%8A.png" alt="">
        <img class="animate__animated animate__fadeInRight " v-show="isTypeTwo==3" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/4%E5%A4%A9%E5%B9%95%E5%B9%BF%E5%91%8A.png" alt="">
        <img class="animate__animated animate__fadeInRight " v-show="isTypeTwo==4" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/5%E5%93%81%E7%89%8C%E5%B9%BF%E5%91%8A.png" alt="">
      </div>
    </div>
    <el-button @click="startFootFrom" class="linshibtn" type="primary">立即抢占海量客户</el-button>
    </div>
            <!-- 表单提交后的提示信息 -->
            <div class="subSuccess " v-show="isSuss">
      <div class="message animate__animated animate__fadeInDown">
        <h2 @click="changeIsSuss">×</h2>
        <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/icon-success.png" alt="">
        <div class="mss-text">
          <h3 class="mess-text-p">信息提交已成功</h3>
          <p>您的专属顾问会在一个工作日内联系您，请您保持电话畅通</p>
          <p>信息提交已成功</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { submitData } from '@/api/http'
export default {
  // components: { zhe },
  data() {
    return {
      isSuss:false,
      isFootFrom: false,
      isplat: 0,
      isTypeTwo: 0,
      isSeven: 0,
      fromData: {
        user: '',
        phone: '',
        name: '',
        businsee:""
      },
      rules: {
        user: [
          { required: true, message: "请输入您的姓名", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "请输入您的联系方式", trigger: "blur" },
          { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: "必须输入正确的电话号码", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入您的公司名称", trigger: "blur" }
        ]
      },
    }
  },
  methods: {
    changeIsSuss(){
      this.isSuss=!this.isSuss
    },
    closeFootFrom() {
      this.isFootFrom = !this.isFootFrom
    },
    startFootFrom() {
      this.isFootFrom = !this.isFootFrom
    },
    submitFromData(fromData) {
      this.$refs[fromData].validate(async (valid) => {
        if (valid) {
          // 当数据有效时
          console.log(this.fromData);
          fromData=this.fromData
          let res = submitData(fromData).then(res => {
            // console.log('res',res.data);
          })
           // 弹起消息提示框
           this.isSuss=true
            // 清空表单数据
            this.$refs.fromData.resetFields()
            // 关闭表单
            this.isFootFrom=false
        }
        else{
            this.$message.error('提交失败')
        }
      })
     
    },
  },
}
</script >

<style lang="less">
.body {
  width: 100vw;
  margin: 0 auto;

  .type-one {
    position: relative;
    .linshibtn {
      position: absolute;
      left: 30%;
      top: 75%;
    }
    width: 100vw;
    height: 100vw;
    margin-top: 10vw;

    .img1 {
      width: 100%;
      height: 100%;
    }
    .img2{
      width: 92vw;
    height: 24vw;
    position: absolute;
    top: 13vw;
    left: 4vw
    }

  }
}

.from-foot {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
 

  .from-foot-from {

    // text-align: right;
    h3 {
      text-align: right;
    }

    width: 100vw;
    height: 100vw;
    background-color: #fff;
    position: fixed;
    bottom: 0;

    .part-foot-from {
      margin-top: 10vw;

      .from-child {
        width: 80vw;
      }
    }

  }
}
.type-two{
  width: 100vw;
  height: 150vw;
  .linshibtn{
    margin-top: 5vw ;
    width: 188px;
  }
  // background-color: aquamarine;
  margin-top: 10vw;
  .type-two-tab{
    width: 100vw;
    height: 25vw;
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    list-style: none;

    li{
      width: 30vw;
      height: 10vw;
      line-height: 10vw;
      border: 1px solid #ccc;
      transition: all .3s linear;
    }
  }
  .type-two-tabchild{
    width: 2px;
    height: 95vw;
    background-color: rgb(64, 158, 255);
    z-index: 1111;
    margin: 0 auto;
    margin-top: -10vw;
  }
  .type-two-cont{
    display: flex;
    justify-content: space-around;
    margin-top: -80vw;
  .type-two-cont-p{
    width: 40vw;
    .p1{
      font-weight: 600;
      font-size: 16px;
    }
    .p2{
      font-size: 14px;
      margin-top: 3vw;
      color: #948f8f;
    }
  }
  .type-two-img{
    width: 40vw;
    height: 85vw;
    img{
      width: 100%;
      height: 100%;  
    }
  }
  }
  .type-active{
    color: #fff;
    background-color: rgb(64, 158, 255);

  }
}
// 表单提交成功后的提示信息
.subSuccess{
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  .message{
    width: 90vw;
    height: 70vw;
    background-color: #fff;
    margin: 20vh auto;
    border-radius: 2vw;
    h2{
      margin-left: 80vw;
      color:#6d6c6c;
    }
    img{
      width: 52px;
      height: 52px;
    }
    .mss-text{
      width: 55vw;
      height: 40vw;
      margin: 5vw auto;
      .mess-text-p{
          margin-bottom: 5vw;
      }
    }
  }
}
</style>